<template>
  <app-dialog
    v-model="model"
    :close-button="false"
    :subtitle="subtitle"
    :title="title"
    :width="500"
    @submit="close({ confirmed: true })"
  >
    <template #footer>
      <div class="flex justify-end gap-4">
        <app-button color="dark" variant="tonal" @click="close()"
          >Cancel</app-button
        >
        <app-button color="danger" type="submit">Confirmer</app-button>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "~/core/i18n/i18n.model";

const model = defineModel<boolean>({ default: false });

defineProps<{
  title: I18nMessage;
  subtitle?: I18nMessage;
}>();

const { close } = useDialog("ConfirmationDialog");
</script>
